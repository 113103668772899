// FULLY DONE

import React from 'react'
import { graphql } from 'gatsby'

//STYLES
import '../styles/dynamic-pages-css/cloud-integration.css'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import InterestedBlock from '../components/interestedBlock'
import { useTranslation } from 'react-i18next'

const Template3 = ({ data }) => {
  let md = data.markdownRemark.frontmatter
  const { t } = useTranslation()

  return (
    <div id="cloud-integration">
      <BackgroundImage
        className="header-img"
        fluid={md.headerImage.childImageSharp.fluid}
        alt="Index header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{t('cloudIntegrationTitle')}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="first-grid">
          <div className="innovation-text">
            <p className="blue-text">
              {t('cloudIntegrationTitle').toUpperCase()}
            </p>
            <h2 className="section-title">{t('cloudIntegrationSubTitle')}</h2>
            <p className="section-ingress">
              {t('cloudIntegrationMainIngress')}
            </p>
            <p className="section-text">{t('cloudIntegrationMainText')}</p>
          </div>
          <div className="innovation-img">
            <Img
              className="working-image gatsby-image"
              fluid={md.mainImage.childImageSharp.fluid}
              alt="workingspace"
            />
          </div>
        </div>
        <div className="apply-block">
          <div>
            <h3 className="apply-text">{t('cloudIntegrationSectionTitle')}</h3>
          </div>
        </div>
        <InterestedBlock
          interestedBlueText={md.interestedBlueText}
          interestedTitle={md.interestedTitle}
          interestedText={md.interestedText}
        />
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        blueText
        mainH1
        mainIngress
        mainText
        sectionBlueText
        sectionH1
        sectionText
        interestedTitle
        interestedText
        interestedBlueText
        lang
        apply
        sectionArray {
          blockTitle
          blockText
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Template3
